
    import { useSelector, useDispatch } from "react-redux"
    import NumberUtil from '../../../lib/NumberUtil';
    
    
	 



var React = require('react');
var Component = React.Component;
var CanvasJSReact = require('canvasjs-react-charts'); 
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

 


const SplatkyAutoGraph = ()=> {
    
    // redux state for the SplatkyContainer
    const splatkyState = useSelector((state) => {
        //console.log( state )
         return state.splatkyAutoReducer;
     });
     
     const splatiliJsme = splatkyState.splatky.reduce((acc, curr) => acc+curr.castka,0);
     
     const zbyvaSplatit = splatkyState.pujckyCelkem - splatiliJsme;
     const splatiliJsmePrc = (splatiliJsme / splatkyState.pujckyCelkem)*100;
     const zbyvaSplatitPrc = (zbyvaSplatit / splatkyState.pujckyCelkem)*100;
     //console.log("zbyvasplatitprc:" + zbyvaSplatitPrc);
     //zbyvaSplatitPrc = NumberUtil.format(zbyvaSplatitPrc, "99,99 %")
     
     
     const splatiliJsmeFmt = NumberUtil.format( splatiliJsme, "999 999 999 999 Kč");
     const zbyvaSplatitFmt = NumberUtil.format( zbyvaSplatit, "999 999 999 999 Kč");
     
   
		const options = {
			animationEnabled: true,
			subtitles: [{
				text:  NumberUtil.format(splatkyState.pujckyCelkem,  "999 999 999 999 Kč"),
				verticalAlign: "center",
				fontSize: 24,
				dockInsidePlotArea: true
			}],
			data: [{
				type: "doughnut",
				showInLegend: false,
				indexLabel: "{name} {y}",
				yValueFormatString: "##.## '%'",
				startAngle: 75,
				dataPoints: [
					{ name: "Splaceno", y: splatiliJsmePrc, toolTipContent: splatiliJsmeFmt },
					{ name: "Zbývá", y: zbyvaSplatitPrc, toolTipContent : zbyvaSplatitFmt }
				]
			}]
		}
		return (
		<div>
		    { splatkyState.dataState === "LOADING" 
		        ? ""
		        : <CanvasJSChart options = {options}/>
		    }
		</div>
		);
	
}

export default SplatkyAutoGraph;
//module.exports = SplatkyGraph2;                              
