import * as React from 'react';
import {useEffect} from 'react';

import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

import Loader from 'react-loader-spinner'

import { useSelector, useDispatch } from "react-redux"
import { RootReducer } from "../../../components/app/reducer"

import SplatkyTable from '../splatkytable'
import PlatbyTable from '../platbytable'

import SplatkyDumGraph from '../graph/orig' 
import useFetchApi from '../../../api/api' 

import useHandleUnauthorized from "../../authorizer/Authorizer"


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 450,
  },}));


  

const HouseContainer : React.FC = () => {
    
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    
    // vsechna data pro dum nyni dotahovana skrze tenhle REST API call
    const fetchSplatkyApi = useFetchApi('https://b9m967lrsa.execute-api.eu-central-1.amazonaws.com/prod/splatky', "GET", undefined );
    const houseAction = useDispatch(); 
    
    // redux state for the SplatkyContainer
    const splatkyState = useSelector((state:RootReducer) => {
        //console.log( state )
         return state.splatkyDumReducer;
     });
    
    
    const handleUnauthorized = useHandleUnauthorized();
    
    useEffect(() => {
      //console.log('start fetching the data')
        //splatkyAction({type:"Splatky/LOADING"}); // triggers display of the loading icon
        
        fetchSplatkyApi()
          .then( (res) => {
            //console.log(result);
              let authresult = handleUnauthorized( res ) 
              if ( ! authresult ) return;
              return res.json();
            },
            error => { 
              console.log(error);
              houseAction({type:"Splatky/LOADFAILED", payload: {state: "Interní chyba", message: "Nepodařilo se načíst data"} } );       
              
            })
            .then ( (result) => {
                if ( ! result ) return
                if ( result.state === "data") {
                  //console.log("Container")
                  //console.log(result)
                    
                    houseAction({type:"Splatky/LOADED", 
                      payload: {
                        splatky: result.splatky.Items , pujcky: result.pujcky.Items, platby: result.platby.Items} } );
                } else {
                    
                    houseAction({type:"Splatky/LOADFAILED", payload: {state: result.state, message: result.message} } );    
                }  
            });
    },[splatkyState.forceReloadCounter]); // empty array to call only at componentmount
    
    
    return (
    <div className={classes.root}>
      <CssBaseline />
     
      <div className={classes.content}>
        
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Tabulka */}
            <Grid item xs={12} md={8} lg={8}>
              { splatkyState.dataState === "LOADING" && 
                <Loader 
                  type="Puff"
                  color="#00BFFF"
                  height={25}
                  width={25}
                  timeout={3000} />
              }
              { splatkyState.dataState === "LOADFAILED" && 
                <div>
                    <Alert severity="error">{splatkyState.errMessage}</Alert>   
                </div>
              }
              <SplatkyTable/>
            </Grid>
            {/* Graf */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <SplatkyDumGraph/>
                {/* div to cover link to canvasjs and trial version */}
                <div style={{ border:"1px solid white", height:"20px", backgroundColor:"white", position:"relative", top:"-15px"  }}></div>
              </Paper>
            </Grid>
          </Grid>
          
          
          
          {/*  sekce pro zobrazeni plateb KASTENU */}
          <Grid container spacing={3}>
            {/* Tabulka */}
            <Grid item xs={12} md={12} lg={12}>
              { splatkyState.dataState === "LOADING" && 
                <Loader 
                  type="Puff"
                  color="#00BFFF"
                  height={25}
                  width={25}
                  timeout={3000} />
              }
              { splatkyState.dataState === "LOADFAILED" && 
                <div>
                    <Alert severity="error">{splatkyState.errMessage}</Alert>   
                </div>
              }
              <PlatbyTable/>
            </Grid>
          </Grid>
        </Container>
      </div>
     
      
    </div>
    );
    
}


export default HouseContainer