import { createBrowserHistory, History } from "history"
import { 
    applyMiddleware, 
    compose, 
    createStore } from "redux"

import { StateType } from "typesafe-actions"
import { connectReducer } from "./reducer"

const history: History = createBrowserHistory()
const reducer = connectReducer(history)

export type State = StateType<typeof reducer> & {
  router: StateType<typeof history>
}

 const composeEnhancer: typeof compose =
   (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose



export default function configureStore(initialState = {}) {
  return createStore(
    reducer,
    initialState,
  )
}