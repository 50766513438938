import { History } from "history"
import { combineReducers } from "redux"
import splatkyDumReducer from '../house/container/reducer'
import splatkyAutoReducer from '../car/container/reducer'


export type AppState = {
    loggedIn: boolean,
    fetching: boolean,
    loginFailed: boolean,
    failureReason: string
    
  }
  
type AppAction =
  | {   type: "App/LOGIN"   }
  | {   type: "App/LOGGEDIN"   }
  | {   type: "App/LOGINFAILED"   }
  | {   type: "App/LOGINFAILEDSYSTEM", payload: any }
  | {   type: "App/LOGOUT"  }
  
const initialState: AppState = {  loginFailed: false, fetching:false, failureReason: "", loggedIn: false }

 const appReducer = (
        state: AppState = initialState,
        action: AppAction 
    ) => {
        // console.log("in AppReducer state without content" + JSON.stringify({blnAppDisclaimerAgreed:state.blnAppDisclaimerAgreed, blnAppDisclaimerRejected:state.blnAppDisclaimerRejected}) + " tms:" + Date.now())
        // console.log("in AppReducer state action: " + action.type + " tms:" + Date.now())
        switch (action.type) {
            case "App/LOGGEDIN" : 
              return { ...state,  fetching: false, loggedIn : true }
            case "App/LOGIN" :
              return { ...state,  fetching: true }
            case "App/LOGINFAILED" :
              return { loginFailed: true, fetching: false, failureReason: "Nesprávná kombinace jméno/heslo", loggedIn: false} 
            case "App/LOGINFAILEDSYSTEM" :
              return { loginFailed: true, fetching: false, failureReason: JSON.stringify(action.payload), loggedIn: false } 
            case "App/LOGOUT":
              return { ...state,  loggedIn: false, loginFailed:false }
            default: return state
        }
    }
  
  const rootReducerInst = combineReducers({
    appReducer,
    splatkyDumReducer,
    splatkyAutoReducer
  // filestoreListReducer,
  // settingReducer,
  // profileReducer,
})




export const connectReducer = (history: History) => rootReducerInst


export type RootReducer = ReturnType<typeof rootReducerInst>