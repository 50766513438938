import * as React from "react";
import { useEffect, useRef, useCallback }  from "react"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Loader from 'react-loader-spinner'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Alert } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import { useSelector, useDispatch } from "react-redux"
import { RootReducer } from "../../components/app/reducer"
import { useCookies } from 'react-cookie';
import useFetchApi from '../../api/api' 
import { useHistory } from "react-router-dom";


import BackgroundSlider from 'react-background-slider' // dev version only! still need to make solid background for the login form and choose pictures
import logo1 from '../../images/IMG-20190407-WA0000.jpg'; 
import logo2 from '../../images/IMG-20190601-WA0002.jpg'; 


//     const [name, setName] = React.useState('Cat in the Hat');

//  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setName(event.target.value);}


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const LoginPage : React.FC = () => {
    
    const classes = useStyles();
    
    // handling cookies here 
    const [cookies, setCookie, removeCookie] = useCookies(['hypo-session-id']);
    // redirect required
    const history = useHistory();
    
    // inputs (Jmeno)
    const [name, setName] = React.useState('');
    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }
    
    // inputs (Heslo)
    const [passphrase, setPassphrase]  =React.useState(''); 
    const handleChangePass = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassphrase(event.target.value);
    }
    
    //inputs (Zapamatovat si prihlaseni)
    const [rememberme, setRememberMe]  =React.useState(true); 
    const handleRememberMe = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRememberMe(event.target.checked);
    }
    
    // api to get login data
    const fetchApi = useFetchApi('https://b9m967lrsa.execute-api.eu-central-1.amazonaws.com/prod/login', "POST", { user: name, pass: passphrase, rem: rememberme });
    
    // redux state for the application 
    const userLoginState = useSelector((state:RootReducer) => {
         return state.appReducer;
     });
    const logInAction = useDispatch();
     
    // handle redirect in case the cookie is already present
    useEffect(() => {
        if ( cookies['hypo-session-id'] ) {
            //alert("Cookie sess: " + cookies['hypo-session-id']);
            console.log("Useeffect");
           history.push("/main")
        }
    },[]); // empty array to only call at render!, no array would mean run on every update and at render, non-empty array means run at render and whenever the variable changes value
     
    // callback for Prihlasit button
    const logIn = useCallback( (evnt) => {
        evnt.preventDefault();
         
        logInAction({type:"App/LOGIN"}); // triggers display of the loading icon
         
        // do the fetch
        fetchApi()
            .then( res => res.json())
            .then( (result) => {
                //console.log(result);
                    if ( result.state === "authorized") {
                        
                        setCookie('hypo-session-id', result.sessionId, { path: '/', sameSite : "strict"});
                        setCookie('hypo-user', name , { path: '/',sameSite : "strict"});
                        
                        logInAction({type:"App/LOGGEDIN"});
                        //console.log("main");
                        history.push("/main")
                    } else {
                        console.log("NOT logged in");
                        logInAction({type:"App/LOGINFAILED"});    
                    } 
                }, error=> { 
                    console.log("in error " + JSON.stringify(error))}  );
         
     }, [name, passphrase, rememberme,setCookie]);
     
     
    
    
    
    return ( 
        <div>
        
<BackgroundSlider
  images={[logo1, logo2 ]}
  duration={10} transition={2} />

         <Container component="main" maxWidth="xs">
      <CssBaseline />
       <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Přihlásit se
        </Typography>
            <form onSubmit={ logIn}  noValidate className={classes.form} autoComplete="off">
                
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="outlined-name" 
                    value={name}
                    onChange={handleChangeName}
                    variant="outlined"
                    autoFocus
                    label="Jméno" /> 
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="outlined-pwd"
                    label="Heslo"
                    type="password"
                    value={passphrase}
                    onChange={handleChangePass}
                    variant="outlined" />
                
                <Button variant="contained" type="submit"   className={classes.submit}>Přihlásit</Button>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={rememberme}
                            onChange={handleRememberMe}
                            name="rememberme"
                            color="primary"/>
                    }
                    label="Zapamatovat si mě na tomto zařízení" />
            
            </form>
            {userLoginState.loginFailed &&
                <div>
                    <Alert severity="error">{userLoginState.failureReason}</Alert>   
                </div>
            }
                
            {userLoginState.fetching &&
                <Loader  
                    type="Puff"
                    color="#00BFFF"
                    height={25}
                    width={25}
                    timeout={3000} />
            }
            </div>
        </Container>
        </div>
        );
    
}

export default LoginPage