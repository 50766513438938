import * as React from 'react';
import {useEffect} from 'react';

import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

import Loader from 'react-loader-spinner'

import { useSelector, useDispatch } from "react-redux"
import { RootReducer } from "../../../components/app/reducer"

import SplatkyAutoTable from '../splatkytable/SplatkyAutoTable'


import SplatkyAutoGraph from '../graph/SplatkyAutoGraph' 
import useFetchApi from '../../../api/api' 


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 450,
  },}));


  

const HouseContainer : React.FC = () => {
    
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    
    const fetchSplatkyGalaxyApi = useFetchApi('https://b9m967lrsa.execute-api.eu-central-1.amazonaws.com/prod/splatkyAuto', "GET", undefined );
    const carAction = useDispatch(); 
    
    // redux state for the CarSplatkyContainer
    const splatkyState = useSelector((state:RootReducer) => {
        return state.splatkyAutoReducer;
    });
    
    useEffect(() => {
        //console.log('start fetching the data')
        //splatkyAction({type:"Splatky/LOADING"}); // triggers display of the loading icon
        
        fetchSplatkyGalaxyApi()
            .then( res => res.json())
            .then( (result) => {
                //console.log(result);
                if ( result.state === "data") {
                    
                    carAction({type:"SplatkyAuto/LOADED", 
                      payload: {
                        splatky: result.splatky.Items } } );
                } else {
                    
                    carAction({type:"SplatkyAuto/LOADFAILED", payload: {state: result.state, message: result.message} } );    
                } 
            },
            error => { 
                console.log(error);
                carAction({type:"SplatkyAuto/LOADFAILED", payload: {state: "Interní chyba", message: "Nepodařilo se načíst data"} } );       
            });
    },[splatkyState.forceReloadCounter]); // empty array to call only at componentmount
    
    
    return (
         <div className={classes.root}>
          <CssBaseline />
     
      <div className={classes.content}>
        
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Tabulka */}
            <Grid item xs={12} md={8} lg={8}>
                { splatkyState.dataState === "LOADING" && 
                  <Loader 
                    type="Puff"
                    color="#00BFFF"
                    height={25}
                    width={25}
                    timeout={3000} />
                }
               { splatkyState.dataState === "LOADFAILED" && 
                <div>
                    <Alert severity="error">{splatkyState.errMessage}</Alert>   
                </div>
                }
               <SplatkyAutoTable/>
            </Grid>
            {/* Graf */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <SplatkyAutoGraph/>
                {/* div to cover link to canvasjs and trial version */}
                <div style={{ border:"1px solid white", height:"20px", backgroundColor:"white", position:"relative", top:"-15px"  }}></div>
              </Paper>
            </Grid>
          </Grid>
          
          
          
          
          
        </Container>
      </div>
     
      
    </div>
    );
    
}


export default HouseContainer