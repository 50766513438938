import { useHistory } from "react-router-dom";
import { useCookies } from 'react-cookie';





const useHandleUnauthorized = ( ) => {
    
    // handling cookies here 
   const [cookies, setCookie, removeCookie] = useCookies();
   const history = useHistory();
   
   return (result:any) => {
       //console.log("Authorizer")
       //console.log(result);
     if ( result &&  result.status == "401") {
        removeCookie('hypo-session-id');
        removeCookie('hypo-user');
        history.push("/login")
        return false;
     }
    return true;    
   }
}

export default useHandleUnauthorized;