import * as React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';

import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

import Paper from '@material-ui/core/Paper';

import {Order, HeadCell, useStyles, EnhancedTableToolbar, descendingDateComparator } from '../../enhancedtable/enhanced';

import {PlatbyTableData} from '../container/reducer';

import { useSelector, useDispatch } from "react-redux"
import { RootReducer } from "../../../components/app/reducer"


import NumberUtil from '../../../lib/NumberUtil';




const headCells: HeadCell[] = [
  { id: 'castka', disablePadding: false, label: 'Částka', alphabetic : false },
  { id: 'poslanodne',  disablePadding: false, label: 'Posláno dne', alphabetic : false },
  { id: 'poznamka',  disablePadding: false, label: 'Poznámka', alphabetic : true }];



const PlatbyTable :React.FC = () => {
  const classes = useStyles();
  
  

  
  // redux state for the SplatkyContainer not using props on the parent just to try out Redux
  const splatkyState = useSelector((state:RootReducer) => {
      //console.log( "house" + state )
       return state.splatkyDumReducer;
   });
   
  const platbyRows: PlatbyTableData[] = splatkyState.platbyKasten;
  

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar tableTitle="Platby Kastenu"/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <TableHead >
              <TableRow>
                <TableCell className={classes.tableHead} align="right">Částka</TableCell>
                <TableCell className={classes.tableHead} align="right">
                  <TableSortLabel  active={true} direction={'desc'}>
                      Posláno dne
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} align="left">Poznámka</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
              
                platbyRows
                  .sort( (a,b) => {
                    return descendingDateComparator( a['poslanodne'], b['poslanodne'])
                  })
                  .map((row, index) => {
                    const strPoznamka = new String( row.poznamka );
                    const strCastkaFmt:string = NumberUtil.format(   NumberUtil.unformat( row.castka.toString() ), "999 999 Kč" );
                    
  
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.platbaid}
                      >
  
                        
                        
                        <TableCell align="right">{strCastkaFmt}</TableCell>
                        <TableCell align="right">{row.poslanodne}</TableCell>
                        <TableCell align="left">{row.poznamka}</TableCell>
                        
                      </TableRow>
                    );
                  })}
              
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}



export default PlatbyTable