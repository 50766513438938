import * as React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import Paper from '@material-ui/core/Paper';

import {Order, HeadCell, useStyles, EnhancedTableToolbar, EnhancedTableHead, stableSort, getComparator } from '../../enhancedtable/enhanced';

import {SplatkyTableData} from '../container/reducer';

import  AddSplatkaDialog from '../../../components/adddialog/AddSplatkaDialog'

import { useSelector, useDispatch } from "react-redux"
import { useCookies } from 'react-cookie';
import { RootReducer } from "../../../components/app/reducer"


import NumberUtil from '../../../lib/NumberUtil';


const headCells: HeadCell[] = [
  { id: 'zuctu',  disablePadding: false, label: 'Z účtu', alphabetic : true },
  { id: 'castka', disablePadding: false, label: 'Částka', alphabetic : false },
  { id: 'poslanodne',  disablePadding: false, label: 'Posláno dne', alphabetic : false },
  { id: 'naucet',  disablePadding: false, label: 'Na účet', alphabetic : true },
  { id: 'poznamka',  disablePadding: false, label: 'Poznámka', alphabetic : true }];



const SplatkyTable :React.FC = () => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof SplatkyTableData>('poslanodne');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof SplatkyTableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  // redux state for the SplatkyContainer not using props on the parent just to try out Redux
  const splatkyState = useSelector((state:RootReducer) => {
      //console.log( "house" + state )
       return state.splatkyDumReducer;
   });
   
  const splatkyRows: SplatkyTableData[] = splatkyState.splatky;
  
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, splatkyRows.length - page * rowsPerPage);
  
  // handling cookies here 
  const [cookies, setCookie, removeCookie] = useCookies(['hypo-user']);
  

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar tableTitle="Přehled splátek"/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={splatkyRows.length}
            />
            <TableBody>
              {stableSort(splatkyRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const strPoznamka = new String( row.poznamka );
                  const strCastkaFmt:string = NumberUtil.format(   NumberUtil.unformat( row.castka.toString() ), "999 999 Kč" );
                  

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.splatkaid}
                    >

                      
                      <TableCell align="left">{row.zuctu}</TableCell>
                      <TableCell align="right">{strCastkaFmt}</TableCell>
                      <TableCell align="right">{row.poslanodne}</TableCell>
                      <TableCell align="left">{row.naucet}</TableCell>
                      { strPoznamka.length > 15 
                        ? <Tooltip title={row.poznamka} arrow>
                            <TableCell align="left">{strPoznamka.substr(0,15) }...</TableCell>
                          </Tooltip>
                        : <TableCell align="left">{strPoznamka}</TableCell>
                      }
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={splatkyRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {cookies['hypo-user'] === 'radek' &&  
          <AddSplatkaDialog apiUrl="https://b9m967lrsa.execute-api.eu-central-1.amazonaws.com/prod/splatkaDum" reducerActionComponent="Splatky"/>
        }
      </Paper>
    </div>
  );
}



export default SplatkyTable