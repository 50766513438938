import * as React from "react";
import { useEffect } from "react";
import { Provider } from "react-redux"
import { HashRouter  } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
import { Logout } from '../logout/logout'
import Container from '@material-ui/core/Container';

import './App.css';
import routes from '../../routes/routes';
import configureStore from "./store"


import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { csCZ } from '@material-ui/core/locale';



// material-ui localization for the components
const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, csCZ);


const store = configureStore();  

const App: React.FC = props => { 
    
    useEffect(() => {
        if(window.location.protocol != 'https:') {
            window.setTimeout(
                function(){ 
                    window.location.href =   "https://www.beneda.eu/"; //window.location.href.replace("http://", "https://");
                }, 100)
        }
    }, [])
    
      
    if ( window.location.protocol !== 'https:') {
        return <div></div>; // do not attempt to load the application
    } else {
    
    return (     
        <div className="App">
        <CookiesProvider>
            <Provider store={store}>
            <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <HashRouter>
                    <Logout/>
                    {routes}
                </HashRouter>
                </Container>
                </ThemeProvider>
                </Provider>
            </CookiesProvider>
            
            
           
            
        </div>
        

    )}
  }

export default App;
