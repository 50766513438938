
import * as  React from 'react'; 
import { useCallback } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid'
import { createStyles,  makeStyles, Theme } from '@material-ui/core/styles';



import {
    MuiPickersUtilsProvider,
  KeyboardDatePicker 
} from '@material-ui/pickers';

import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom";
import { useCookies } from 'react-cookie';

import DateUtil from "../../lib/DateUtil";
import {FMT_DATE_DB} from  "../../lib/DateUtil";
import useFetchApi from '../../api/api' 
import useHandleUnauthorized from "../authorizer/Authorizer"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButton: {
      marginBottom: theme.spacing(1),
      //marginTop: theme.spacing(1)
    },
  }),
);

interface AddSplatkaDialogProps { 
    apiUrl : string,
    reducerActionComponent : string
};





const AddSplatkaDialog : React.FC<AddSplatkaDialogProps> = (props : AddSplatkaDialogProps) => {
    const classes = useStyles();
     
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
   const history = useHistory();
   
   // handling cookies here 
   const [cookies, setCookie, removeCookie] = useCookies();

    
    
    
    
    // inputs (Z uctu)
    const [zUctu, setZUctu]  =React.useState('2000348444 / 2010'); 
    const handleChangeZUctu = (event: React.ChangeEvent<HTMLInputElement>) => {
        setZUctu(event.target.value);
    }
    
    //inputs (castka)
    const [castka, setCastka]  =React.useState("20000"); 
    const handleChangeCastka = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCastka(event.target.value);
    }
    
    // inputs (na ucet)
    const [naUcet, setNaUcet]  =React.useState('1024310227 / 0100'); 
    const handleChangeNaUcet = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNaUcet(event.target.value);
    }
    
    // inputs (poslano)
    const today = new Date();
    const [poslano, setPoslano] = React.useState(today)
    const handleChangePoslano = (date: Date | null) => {
        setPoslano(date);
    } 
    
    // inputs (poznamka)
    const [poznamka, setPoznamka]  =React.useState(''); 
    const handleChangePoznamka = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPoznamka(event.target.value);
    }
    
    const action = useDispatch();
    
    const handleUnauthorized = useHandleUnauthorized();
    
    const fetchApi = useFetchApi( props.apiUrl, "POST", 
        {z_uctu: zUctu
         , castka: castka
         , na_ucet: naUcet
         , poslano : DateUtil.format(poslano, FMT_DATE_DB) 
         , poznamka: poznamka} );
    
    const handleSubmit = useCallback(() => {
        fetchApi().
            then(
                (result)=>{ 
                    //console.log(result);
                    setOpen(false);
                    let authresult = handleUnauthorized( result )  
                    
                    const loadingAction = "" + props.reducerActionComponent + "/LOADING";
                    authresult && action({ type: loadingAction});
                    
                }
                ,(error) => { alert( 'Neulozeno') }
            )
    }, [fetchApi]) 
    
    return (
        <div>
            <Button className={classes.addButton} variant="outlined" color="primary" onClick={handleClickOpen}>
                Splátka +
            </Button>
            <Dialog      open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Nová splátka</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Níže zadej údaje k nové splátce. Zaplatit musíš sám :-)
                    </DialogContentText>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid  container justify="space-around">
               
                            <TextField
                                autoFocus
                                margin="dense"
                                id="zuctu"
                                label="Z účtu"
                                type="text"
                                value={zUctu}
                                onChange={handleChangeZUctu}/>
                            <TextField
                                margin="dense"
                                id="castka"
                                label="Částka (Kč)"
                                type="number"
                                value={castka}
                                onChange={handleChangeCastka}/>
                            <TextField
                                margin="dense"
                                id="naucet"
                                label="Na účet"
                                type="text"
                                value={naUcet}
                                onChange={handleChangeNaUcet}/>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd.MM.yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Posláno dne"
                                value={poslano}
                                onChange={handleChangePoslano}
                                KeyboardButtonProps={{
                                'aria-label': 'change date'}}/>
                            <TextField
                                margin="dense"
                                id="poznamka"
                                label="Poznámka"
                                type="text"
                                value={poznamka}
                                onChange={handleChangePoznamka}/>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Zrušit
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Uložit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
  );
}


export default AddSplatkaDialog;