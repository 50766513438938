import * as React from "react";
import { useCallback }  from "react"
import { useHistory, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';


import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  header: {
    //borderBottom: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
}));



export const Logout: React.FC = () => {
    
    const location  = useLocation().pathname;
    
    // handling cookies here 
    const [cookies, setCookie, removeCookie] = useCookies(['hypo-session-id']);

    const handleOnClick = useCallback( (event: React.MouseEvent<HTMLAnchorElement>)=>{
        removeCookie('hypo-session-id');
        removeCookie('hypo-user');
    },[])

    const classes = useStyles();

  
    if ( location === "/login" ) {
        return null;
    } 

    return (    
        
        <Container maxWidth="md" component="footer" className={classes.header}>
        <Grid container spacing={4} justify="flex-end">
          
                    <Link href="/#login" onClick={handleOnClick} variant="subtitle1" color="textSecondary">
                      Odhlásit
                    </Link>
              
          
        </Grid>
      </Container>
        
        
        
     
    )
  }
  
  
  export default Logout