
const FORMAT_YMDHMS = '%Y-%M-%D %h:%m:%s';
  const FORMAT_YMDHM = '%Y-%M-%D %h:%m';
  const FORMAT_DMYHM = '%D-%M-%Y %h:%m';


export const FMT_DATE_DB = "%Y-%M-%D";
export const FMT_DATE_UI = "%D.%M.%Y";

export default class DateUtil {

    /**
     * @param dateIn Date to format
     * @param mask Format string recognizing %Y - year, %M - month, %D - date, %h - hour, %m - min, %s - secs, %t - millis
     * @returns string in given format
     */    
    static format( dateIn: Date, mask: string) : string {
        var yearToken = "%Y";
      var monthToken = "%M";
      var dateToken = "%D";
      var hourToken = "%h";
      var minuteToken = "%m";
      var secondsToken = "%s";
      var millisToken = "%t";
      var percentToken = "%%";

      var year = "" + dateIn.getFullYear();
      var month =  (dateIn.getMonth() + 1) < 10 ? "0" + (dateIn.getMonth() + 1) : "" + (dateIn.getMonth() + 1); // zero based
      var date = dateIn.getDate() < 10 ? "0" + dateIn.getDate() : "" + dateIn.getDate();
      var hours = dateIn.getHours() < 10 ? "0" + dateIn.getHours() : "" + dateIn.getHours();
      var minutes = dateIn.getMinutes() < 10 ? "0" + dateIn.getMinutes() : "" + dateIn.getMinutes();
      var secs = dateIn.getSeconds() < 10 ? "0" + dateIn.getSeconds() : "" + dateIn.getSeconds();
      var millis = "" + dateIn.getMilliseconds();

      var result = mask;

      if ( result.indexOf( yearToken ) !== -1 ) {
          result = result.replace( yearToken, year ); 
      }
      if ( result.indexOf( monthToken ) !== -1 ) {
          result = result.replace( monthToken, month);
      }
      if ( result.indexOf( dateToken ) !== -1 ) {
          result = result.replace( dateToken, date );
      }
      if ( result.indexOf( hourToken ) !== -1 ) {
          result = result.replace( hourToken, hours );
      }
      if ( result.indexOf( minuteToken ) !== -1 ) {
          result = result.replace( minuteToken, minutes );
      }
      if ( result.indexOf( secondsToken ) !== -1 ) {
          result = result.replace( secondsToken, secs );
      }
      if ( result.indexOf( millisToken ) !== -1 ) {
         result = result.replace( millisToken , millis );
      }
      if ( result.indexOf( percentToken ) !== -1) {
         result = result.replace( percentToken , '%' );
      }
      return result;
    }
    
    
    // parseDate ( "Hello1992-24::03 ", "Hello%Y-%D::%M" );
    /**
     * @param toParse string to be parsed
     * @param formatString string can be anything containing any date tokens %Y %D %M etc
     * @return Date parsed date
     */
    static parse( toParse: string, formatString: string ) : Date {

      var yearToken = "%Y";
      var monthToken = "%M";
      var dateToken = "%D";
      var hourToken = "%h";
      var minuteToken = "%m";
      var secondsToken = "%s";
      var millisToken = "%t";
      var percentToken = "%%";

      var nbrYear = 0;
      var nbrMonth = 0;
      var nbrDate = 0;
      var nbrHours = 0;
      var nbrMinutes = 0;
      var nbrSecs = 0;
      var nbrMillis = 0;

      var separatorString = "";
      var tokenPart = "";

      var str = toParse;
      var fmt = formatString;

      //var resultDate = new Date (0, 0, 0, 0, 0, 0, 0);
      while ( true ) {
          //console.log("Start ITERATION");
          var startIndex; // of the value in the string
          var endIndex; // of the vallue in the string
          var strToParse;
          var tokenPos = fmt.indexOf('%');
          
          if ( tokenPos === -1 ) { 
              //console.log("END Parsing");
              break; 
          } // parsing ends
          var token = fmt.substr( tokenPos, 2);
          //console.log("Detected token: [" + token + "]");

          startIndex = tokenPos;

          var nextTokenPos = fmt.indexOf('%', tokenPos + 1); // search for the next percent sign after the first found
          var nextSeparator;
          if ( nextTokenPos === -1 ) {
              nextSeparator = fmt.substring( tokenPos + 2)
          } else {
              nextSeparator = fmt.substring(tokenPos + 2, nextTokenPos ); // token has 2 chars so at tokenPos + 2 starts the separator and is up to (excluding) next token pos
          }
      
          //console.log("Next Separator: [" + nextSeparator + "]");

          endIndex = str.indexOf(nextSeparator, startIndex);
          //console.log("End index: " + endIndex);
          if (endIndex === -1 ) {
              console.log("util date Error. Original Input: [" + toParse + "], Format String: [" + formatString + "], Parse Segment: [" + str + "], Format Segment: [" + fmt + "]");
              return null!;
          } else {
              if ( nextSeparator === "" ) {
                  strToParse = str.substring(startIndex); // to the end of the string
              } else {
                  strToParse = str.substring(startIndex, endIndex);
              }
          }
          //console.log("To parse: [" + strToParse + "]");

          // handle token and strToParse - validate and assign the correct value on date
          var numForDate = parseInt( strToParse, 10 );
          //console.log("Parsed number: " + numForDate  + " Token: " + token);
          if ( ! isFinite( numForDate ) || isNaN( numForDate ) ) {
              console.log ("Unable to parse number from [" + strToParse + "]");
              return null!;
          }
          if ( token === yearToken ) {
              //resultDate.setFullYear( numForDate );
              nbrYear = numForDate;
          } else if ( token === monthToken ) {
              if ( numForDate < 1 || numForDate > 12 ) {
                  console.log("Month must be between 1 and 12 [" + strToParse + "]");
                  return null!;
              }
              //console.log( "Setting month:" + (numForDate - 1));
              //resultDate.setMonth( numForDate - 1 ); // zero based
              //console.log( "numfordate type" + ( typeof numForDate)); 
              //console.log( "Set month:" + resultDate.getMonth());
              nbrMonth = numForDate - 1;
          } else if ( token === dateToken ) {
              if ( numForDate < 1 || numForDate > 31 ) {
                  console.log("Date must be between 1 and 31 [" + strToParse + "]");
                  return null!;
              }
              //resultDate.setDate( numForDate );
              nbrDate = numForDate;
          } else if ( token === hourToken ) {
              if ( numForDate < 0 || numForDate > 23 ) {
                  console.log("Hours must be between 0 and 23 [" + strToParse + "]");
                  return null!;
              }
              //resultDate.setHours( numForDate );
              nbrHours = numForDate;
          } else if ( token === minuteToken ) {
              if ( numForDate < 0 || numForDate > 59 ) {
                  console.log("Minutes must be between 0 and 59 [" + strToParse + "]");
                  return null!;
              }
              //resultDate.setMinutes( numForDate );
              nbrMinutes = numForDate;
          } else if ( token === secondsToken ) {
              if ( numForDate < 0 || numForDate > 59 ) {
                  console.log("Seconds must be between 0 and 59 [" + strToParse + "]");
                  return null!;
              }
              //resultDate.setSeconds( numForDate );
              nbrSecs = numForDate;
          } else if ( token === millisToken ) {
              if ( numForDate < 0 || numForDate > 999 ) {
                  console.log("Milliseconds must be between 0 and 999 [" + strToParse + "]");
                  return null!;
              }
              //resultDate.setMilliseconds( numForDate );
              nbrMillis = numForDate;
          } else if ( token === percentToken ) {
              // do nothing just ignore the string
          } else {
              console.log("Unrecognized token [" + token + "] Original Input: [" + toParse + "], Format String: [" + formatString + "], Parse Segment: [" + str + "], Format Segment: [" + fmt + "]");
              return null!;
          }

          // change string and fmt
          fmt = fmt.substring( tokenPos + 2);
          str = str.substring( endIndex );
          //console.log("Segments for next iteration. Parse Segment: [" + str + "], Format Segment: [" + fmt + "]");


      }
      var resultDate = new Date(nbrYear, nbrMonth, nbrDate, nbrHours, nbrMinutes, nbrSecs, nbrMillis);
      return resultDate;

  }
    
    
}
