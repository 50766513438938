import React from "react"

type Props = {
    adtx?: string
}

const NoMatch: React.FC<Props> = (props) => {
  return (
    <div className="card flex-fill">
        <div className="card-header">Error 404</div>
        <div className="card-body">  
            <ul >
                { props.adtx && <li className="codelist-header bold">{props.adtx}</li>}
                <li className="codelist-header bold">Page not found</li>
            </ul>
        </div>
    </div>
  )
}

export default NoMatch