import DateUtil from '../../../lib/DateUtil';
import { FMT_DATE_DB, FMT_DATE_UI } from '../../../lib/DateUtil';

import NumberUtil from '../../../lib/NumberUtil';


// component data
export interface SplatkyTableData {
  splatkaid: string;
  zuctu: string;
  castka: number;
  poslanodne: string;
  naucet: string;
  poznamka: string;
}

export interface PlatbyTableData {
  platbaid : string;
  castka : number;
  poslanodne : string;
  poznamka : string;
}


// db data
 // SPlatky do Duba
 interface SplatkyData {
      splatka_id : string;
      castka: string;
      na_ucet: string;
      posl_zmena: string;
      poslano: string;
      poznamka: string;
      z_uctu: string;
  }
  
  // pujcky z Duba
  interface PujckyData {
      pujcka_id : string;
      castka: string;
      datum_obdrzeno: string;
      ucet: string;
      z_uctu: string;
  }
  
  // platby Kastenu
  interface PlatbyData {
    platba_kasten_id : string;
    castka: string;
    poslano: string;
    poznamka: string;
  }
  
  // REST API response
  interface SplatkyResponse {
      state: string;
      message: string;
      splatky?: SplatkyData[]
      pujcky?: PujckyData[]
      platby? : PlatbyData[]
  }
  




export type SplatkyState = {
    dataState:  "LOADING" 
        |  "LOADED"
        |  "LOADFAILED",
    splatky: SplatkyTableData[],
    pujckyCelkem: number,
    errMessage: string,
    platbyKasten: PlatbyTableData[],
    forceReloadCounter : number
  }
  

  
type SplatkyAction =
    {   type: "Splatky/LOADING" } // used to force-reload the component
  | {   type: "Splatky/LOADED", payload: SplatkyResponse    }
  | {   type: "Splatky/LOADFAILED", payload: SplatkyResponse   }
  
const initialState: SplatkyState = {  dataState : "LOADING", splatky: [], pujckyCelkem: 0, errMessage : "", platbyKasten : [], forceReloadCounter: 0 }

const transformForComponent = ( 
        splatky: SplatkyData[], 
        pujcky: PujckyData[],
        platby: PlatbyData[], 
        currentState : SplatkyState
  ): SplatkyState => {
  
    const pujckyCelkemValue: number = pujcky.reduce( (prevVal:number, currPujcka:PujckyData) => (prevVal + parseInt(currPujcka.castka, 10)), 0 );
    
    
    const splatkyData: SplatkyTableData[] = 
      splatky.map((
        item: SplatkyData
      ) => {
        const newItem: SplatkyTableData = 
        { 
          splatkaid   : item.splatka_id,
          zuctu       : item.z_uctu, 
          castka      : parseInt( item.castka, 10),
          naucet      : item.na_ucet,
          poznamka    : item.poznamka,
          poslanodne  : DateUtil.format( DateUtil.parse(item.poslano, FMT_DATE_DB)  , FMT_DATE_UI)
        }
        return newItem; 
      }) || [];
      
    const platbyData : PlatbyTableData[] = 
      platby.map((
        item: PlatbyData
      ) => {
        const newItem : PlatbyTableData = 
        {
          platbaid    : item.platba_kasten_id,
          castka      : parseFloat(item.castka),
          poslanodne  : DateUtil.format( DateUtil.parse(item.poslano, FMT_DATE_DB)  , FMT_DATE_UI),
          poznamka    : item.poznamka
        }
        return newItem;
      }) || [];
      
    
    const state: SplatkyState  = {dataState : "LOADED", pujckyCelkem: pujckyCelkemValue, splatky: splatkyData, platbyKasten: platbyData ,errMessage: "", forceReloadCounter: currentState.forceReloadCounter};
    return state ;
    
  }; 




 const splatkyDumReducer = (
        state: SplatkyState = initialState,
        action: SplatkyAction 
    ) => {
        // console.log("in AppReducer state without content" + JSON.stringify({blnAppDisclaimerAgreed:state.blnAppDisclaimerAgreed, blnAppDisclaimerRejected:state.blnAppDisclaimerRejected}) + " tms:" + Date.now())
        // console.log("in AppReducer state action: " + action.type + " tms:" + Date.now())
        switch (action.type) {
            case "Splatky/LOADING" : 
              return { ...state, forceReloadCounter : state.forceReloadCounter+1 } // changing the value will force reload, as the useEffect depends on that value
            case "Splatky/LOADED" :
              return { ...transformForComponent( action.payload.splatky || [], action.payload.pujcky|| [], action.payload.platby || [], state)  }
            case "Splatky/LOADFAILED" :
              return { ...state, dataState: "LOADFAILED", errMessage: action.payload.message  }
            default: return state
        }
    }
    
export default splatkyDumReducer