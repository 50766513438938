import * as React from "react";
import { Redirect, Route, Switch } from "react-router"
import NoMatch from "./no-match"
import LoginPage from "../modules/login/login"
import MainPage from "../modules/main/main"


const routes = (
    <Switch>
        <Redirect exact from="/" to="/login" />
        <Route path="/login" exact >
            <LoginPage/>
        </Route>
        <Route path="/main" exact>
            {/*    <HouseSplatky> */}
            <MainPage/>
        </Route>
        <Route path="/galaxy" exact>
            <NoMatch adtx="Galaxy"/>
            {/* <GalaxySplatky> */}
        </Route>
        
        {/*  depositories follow different schema, one page for criteria, one for results, one for detail, other pages have criteria and results merged
        <Route path="/depocriteria" exact >
            <DepositoryCriteria/> 
        </Route>
        <Route path="/deporesults" exact >
            <DepositoryResults/> 
        </Route>
        <Route path="/depo/:depcode" component={DepoDetail} />
        */}
        <Route path="/about">Hello from About R</Route>
        <Route>
            <NoMatch/>
        </Route>
  </Switch>
  )

  export default routes