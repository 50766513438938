

    // the tokens should be defined at code-time, the offset refere to the variables so it should be safe to change the length of the token
    const DECIMAL_SEPARATOR = ".";
    const MINUS_TOKEN = '-';
    const SIGN_TOKEN = "%SG%";
    const DEC_SEP_TOKEN = "%DC%";
    const ST_DEC_SEP_TOKEN = DEC_SEP_TOKEN.charAt(0);
    const DIGIT_TOKEN = "9";
 


export default class NumberUtil {
    /**
     * Formats the number using a mask
     * format of mask 
     * 9 for digit
     * %DC% for decimal separator
     * %SG%  sign - the position of the token is not checked
     * rest is just copied into the result
     */
    static format( nbr: number, mask: string) {
        
        if (typeof mask === "undefined" || mask === null) return "" + nbr;

        var strNbr = "" + nbr; 
        var strSign = "";
        if (strNbr.charAt(0) === '-') {
            strSign = "-";
            strNbr = strNbr.substring(1);
        } else if (strNbr.charAt(0) === '+' ) {
            strSign = "+";
            strNbr = strNbr.substring(1);
        } else {
            strSign = "+";
        }
        var decPointPos = strNbr.indexOf(DECIMAL_SEPARATOR);

        var strInteger = strNbr.substring(0, decPointPos === -1 ? strNbr.length : decPointPos);
        var strDecimal = strNbr.substring( decPointPos === -1 ? strNbr.length : decPointPos + 1);

        decPointPos = mask.indexOf(DEC_SEP_TOKEN);

        var strIntMask = mask.substring(0, decPointPos === -1 ? mask.length : decPointPos);
        var strDecMask = mask.substring( decPointPos === -1 ? mask.length : decPointPos + DEC_SEP_TOKEN.length);

        var digitIndex = strInteger.length - 1;
        var blnSignAlreadyInserted = false;
        // construct the integer part from the lowest order
        var strIntRes = "";
        var i = strIntMask.length -1;
        for( ; i >= 0; i-- ) {

            // insert from the integer number
            if ( strIntMask.charAt(i) === DIGIT_TOKEN ) {
                strIntRes = strInteger.charAt(digitIndex--) + strIntRes;
            // possibly a token check if token
            } else if ( strIntMask.charAt(i) === ST_DEC_SEP_TOKEN ) {
                var strToken = strIntMask.substring ( Math.max(0, i-DEC_SEP_TOKEN.length + 1 ), i + 1);
                if ( strToken === SIGN_TOKEN ) {
                    i = i - SIGN_TOKEN.length + 1; 
                    strIntRes = strSign + strIntRes;
                    blnSignAlreadyInserted = true;
                // not a token
                } else {
                    strIntRes = strIntMask.charAt(i) + strIntRes;
                }
            // not a digit not a token...copy from mask 
            } else {
                strIntRes = strIntMask.charAt(i) + strIntRes;
            }

            // no more chars to process
            if ( digitIndex === -1 ) {
                if ( nbr < 0 && ! blnSignAlreadyInserted ) {
                    strIntRes = "-" + strIntRes;
                    blnSignAlreadyInserted = true;
                }
                // output anything in front of the first digit sign or the %SG% sign
                var firstDigit = strIntMask.indexOf(DIGIT_TOKEN);
                var firstSign = strIntMask.indexOf(SIGN_TOKEN);
                if ( firstSign !== -1 && ! blnSignAlreadyInserted ) {
                    strIntRes = strSign + strIntRes;
                }
                var firstToken;
                // avoid negative values in te indices
                // in a rare case where the the int part is not having any format charracters
                if (firstDigit === -1 && firstSign === -1) {
                    firstToken = strIntMask.length;
                } else if ( firstSign === -1 && firstDigit !== -1) {
                    firstToken = firstDigit;
                } else if ( firstSign !== -1 && firstDigit === -1) {
                    firstToken = firstSign;
                } else {
                    firstToken = Math.min(firstDigit , firstSign);
                }
                strIntRes = strIntMask.substring(0, firstToken) + strIntRes;

                break; // no more digits to process
            }   // if 
        } // for
        
        // construct the decimal part from the highest order
        var strDecRes = "";
        digitIndex = 0; i = 0; 
        for ( ; i < strDecMask.length; i++) {
            if ( strDecMask.charAt(i) === DIGIT_TOKEN ) {
                strDecRes = strDecRes + strDecimal.charAt(digitIndex++) ;
            } else {
                strDecRes = strDecRes + strDecMask.charAt(i);
            }

            // no more chars to process
            if ( strDecimal.length === digitIndex ) {
                // output everything from the mask after the last digit 
                var lastDigit = Math.max(strDecMask.lastIndexOf(DIGIT_TOKEN),0); 
                strDecRes = strDecRes + strDecMask.substring( lastDigit + 1 );
                break;
            }
        }

        //concat using the separator (.)
        if ( strDecRes === "") {
            return strIntRes;
        } else {
            return  strIntRes + DECIMAL_SEPARATOR + strDecRes;    
        }
        

    }// end function
    
    /**
     * @param numberRepresantation - number in string form to be parsed
     * @returns number that is repesented by the string
     * This function basically parses anything by using only the number-important  chars
     */
    static unformat ( numberRepresantation:string ) {

        var decPointPos = numberRepresantation.indexOf(DECIMAL_SEPARATOR);

        var strInteger = numberRepresantation.substring(0, decPointPos === -1 ? numberRepresantation.length : decPointPos);
        var strDecimal = numberRepresantation.substring( decPointPos === -1 ? numberRepresantation.length : decPointPos + 1);


        // construct the integer part from the lowest order
        var nextNum;

        var intRes = 0;
        var intOrder = 1;
        var digInd = strInteger.length -1;
        for( ; digInd >= 0; digInd-- ) {

            if ( strInteger.charAt( digInd ) === '-' ) {
                intRes *= -1;
            } else if ( strInteger.charAt( digInd ) === '+' ) {
              // do nothing!
            } else {
                nextNum = parseInt( strInteger.charAt( digInd ) );
                if ( isNaN( nextNum ) ) {
                  // simply ignore it
                } else {
                    intRes = intRes + (intOrder * nextNum);
                    intOrder *= 10; 
                }
            }
        }

        // construct te decimal part fro mthe highest order
        var decOrder = 1;
        var decRes = 0;
        digInd = strDecimal.length - 1;
        for ( ; digInd >= 0; digInd--) {

            nextNum = parseInt( strDecimal.charAt( digInd ) );
            if ( isNaN( nextNum ) ) {
              // simply ignore it
            } else {
                decRes += (decOrder * nextNum);
                decOrder *= 10; 
            }
        }
        decRes = decRes / decOrder;
        if ( intRes < 0 ) {
            decRes *= -1;
        }

        return intRes + decRes;
    }    
    
    
}

