import React from 'react';

import { useCookies } from 'react-cookie';



function useFetchApi (url:string, httpmethod: string ,httpbody: any)  :any {

    const [cookies, setCookie] = useCookies(['hypo-session-id']);
    const fetchApi = ( ) => {
        //console.log("URL: " + url );
        
        
        
        
        
        if ( httpbody ) {
            let options = {
                method: httpmethod,
                headers: {  'Content-Type': 'application/json',
                            'X-Cookie-Hypo-Session-Id'  :cookies['hypo-session-id']
                              },
                body: JSON.stringify(httpbody)
            }
            return fetch(url, options); // Promise    
        }  else {
            let options = {
                method: httpmethod,
                headers: {  'Content-Type': 'application/json',
                            'X-Cookie-Hypo-Session-Id' :cookies['hypo-session-id']
                             }
            }
            return fetch(url, options); // Promise    
        }
        
        
        //console.log("Promissing: " + url );
    } 
    
    return fetchApi

    
}

 export default useFetchApi