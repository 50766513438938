import DateUtil from '../../../lib/DateUtil';
import { FMT_DATE_DB, FMT_DATE_UI } from '../../../lib/DateUtil';

import NumberUtil from '../../../lib/NumberUtil';


// component data
export interface SplatkyAutoTableData {
    splatkaid: string;
    zuctu: string;
    castka: number;
    poslanodne: string;
    naucet: string;
    poznamka: string;
}

// db data
// SPlatky do Duba za auto
interface SplatkyAutoData {
    splatka_id : string;
    castka: string;
    na_ucet: string;
    poslano: string;
    poznamka: string;
    z_uctu: string;
}
  
  
// REST API response
interface SplatkyResponse {
    state: string;
    message: string;
    splatky?: SplatkyAutoData[]
}
  

export type SplatkyState = {
    dataState:  "LOADING" 
        |  "LOADED"
        |  "LOADFAILED",
    splatky: SplatkyAutoTableData[],
    pujckyCelkem: number,
    errMessage: string,
    forceReloadCounter : number
}
  

  
type SplatkyAction =
    { type: "SplatkyAuto/LOADING"} 
    |   {   type: "SplatkyAuto/LOADED", payload: SplatkyResponse    }
    |   {   type: "SplatkyAuto/LOADFAILED", payload: SplatkyResponse   }
  
// hardocoded celkova pujcka
const initialState: SplatkyState = {  dataState : "LOADING", splatky: [], pujckyCelkem: 859000, errMessage : "", forceReloadCounter:0 }

const transformForComponent = ( 
    splatky: SplatkyAutoData[], 
    currentState : SplatkyState
): SplatkyState => {
    const splatkyData: SplatkyAutoTableData[] = 
        splatky.map((
            item: SplatkyAutoData
        ) => {
            const newItem: SplatkyAutoTableData = 
            { 
                splatkaid   : item.splatka_id,
                zuctu       : item.z_uctu, 
                castka      : parseInt( item.castka, 10),
                naucet      : item.na_ucet,
                poznamka    : item.poznamka,
                poslanodne  : DateUtil.format( DateUtil.parse(item.poslano, FMT_DATE_DB)  , FMT_DATE_UI)
            }
            return newItem; 
        }) || [];
    
    const state: SplatkyState  = {dataState : "LOADED", pujckyCelkem: 859000, splatky: splatkyData, errMessage: "", forceReloadCounter: currentState.forceReloadCounter};
    return state ;
    
  }; 



const splatkyAutoReducer = (
    state: SplatkyState = initialState,
    action: SplatkyAction 
) => {
    // console.log("in CarContainerReducer state without content" + JSON.stringify({blnAppDisclaimerAgreed:state.blnAppDisclaimerAgreed, blnAppDisclaimerRejected:state.blnAppDisclaimerRejected}) + " tms:" + Date.now())
    // console.log("in CarContainerReducer state action: " + action.type + " tms:" + Date.now())
    switch (action.type) {
        case "SplatkyAuto/LOADING" : 
            return { ...state, forceReloadCounter : state.forceReloadCounter+1 } // changing the value will force relod, as the useEffect depends on that value
        case "SplatkyAuto/LOADED" :
          return transformForComponent( action.payload.splatky || [], state)
        case "SplatkyAuto/LOADFAILED" :
          return { ...state, dataState: "LOADFAILED", errMessage: action.payload.message  }
        default: return state
    }
}
    
export default splatkyAutoReducer